<template>
    <div class="pr page-index">
        <div class="plan-search uf uf-pc">
            <div class="wi50 uf uf-pc pr">
                <el-input class="input" placeholder="请输入需要的方案" v-model="planSearch"
                          @keyup.enter.native="search"></el-input>
                <el-button class="btn" type="primary" @click="search">搜索</el-button>
            </div>
        </div>
        <div class="banner-box">
            <div class="uf">
                <div class="banner uf-f1 pr header-container">
                    <div class="header-wrap">
                        <div class="header-container">
                            <!--轮播图 begin-->
                            <div class="pr" style="width: 100vw;height:100vh">
                                <el-carousel :interval="5000" arrow="always" ref="carousel" class="wi100 hi100"
                                             @change="bannerChange">
                                    <el-carousel-item class="cp" v-for="(item, index) in trades" :key="index"
                                                      @click.native="bannerClick(index)">
                                        <el-image class="wi100 hi100" :src="item.bg" fit="cover"></el-image>
                                        <div class="ban-dec uf uf-ver uf-pc">
                                            <div class="dec-box">
                                                <div class="dec-tit fwb">{{ item.name }}</div>
                                                <!--                        <div class="dec-dec">-->
                                                <!--                          覆盖全行业、打通全产业链，可以帮助政府和企业更好地了解市场趋势、竞争对手、消费者需求等信息，从而更好地掌握市场动态和趋势，从而制定更有效的战略和决策，提高企业的竞争力和盈利能力。-->
                                                <!--                        </div>-->
                                            </div>
                                        </div>
                                    </el-carousel-item>
                                </el-carousel>
                                <div class="trades-navs uf uf-pc">
                                    <div class="tn-list" v-for="(item, index) in trades" :key="index"
                                         :class="{on: index === curTradeIndex}" @click="changeTrade(index)">
                                        <el-image class="wi100 hi100" :src="item.bg" fit="cover"></el-image>
                                        <div class="tn-dec uf uf-ver uf-ac uf-pc">
                                            <i :class="`iconfont ${item.icon}`"></i>
                                            <div class="tn-name">{{ item.name }}</div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!--轮播图 end-->
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--    <div class="content-box mt-4 pt-2">-->
        <!--      <div class="any-box uf uf-ac uf-pj fwrap-w">-->
        <!--        <div class="a-list" v-for="(item, index) in anys" :key="index" :style="{background: `linear-gradient(125deg, ${item.color1} ,${item.color2})`}">-->
        <!--          <count-to class="f30 fwb val" :startVal="0" :endVal="item.val" :style="{color: item.color3}"/>-->
        <!--          <div class="mt-2 f16 fwb">{{ item.name }}</div>-->
        <!--          <i :class="`iconfont ${item.icon}`"></i>-->
        <!--        </div>-->
        <!--      </div>-->
        <!--    </div>-->
        <div class="box1 uf uf-ver">
            <div class="title-wrap uf uf-pc pr">
                <div class="title-icon"></div>
                <div class="title-name">智能制造</div>
                <div class="title-icon"></div>
            </div>
            <div class="uf uf-ac uf-pc uf-f1">
                <div class="p-list uf uf-pc cp" :class="{on: index === curPlanIndex}" v-for="(item, index) in plans"
                     :key="index" @mouseenter="curPlanIndex = index" :style="{'background-position': item.po}">
                    <div class="uf uf-ver uf-pj uf-ac">
                        <div class="p-name uf-f1">{{ item.name }}</div>
                        <div class="heng"></div>
                        <div class="p-num">{{ '0' + (index + 1) }}</div>
                    </div>
                    <div class="detail-box" v-if="index === curPlanIndex">
                        <div class="detail-list uf uf-ac" v-for="(d, dIndex) in item.detail" :key="dIndex">
                            <div class="mr-2 f12">▶</div>
                            <div>{{ d }}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!--    <main-tools></main-tools>-->
    </div>
</template>

<script>
// import MainTools from './main-tools'
// import CountTo from 'vue-count-to'

export default {
    name: 'self-service',
    components: {
        // CountTo
    },
    beforeRouteEnter(to, from, next) {
        if (from.name === 'login') {
            next(vm => {
                if (vm.unRead > 0) {
                    vm.$notify.info({
                        title: '消息提醒',
                        message: '你有' + vm.unRead + '条未读消息',
                        offset: 100
                    })
                }
            })
        } else {
            next()
        }
    },
    data() {
        return {
            curTradeIndex: 0,
            planSearch: '',
            bannerLocal: [
                {
                    name: '生物医药行业',
                    bg: require('../../assets/img/index/shengWuYiYao.jpg'),
                }
            ],
            trades: [
                {
                    itemCode: '01',
                    name: '生物医药行业',
                    bg: require('../../assets/img/index/shengWuYiYao.jpg'),
                    icon: 'icon-shengwuyiyao',
                    color: '#409EFF',
                    color2: '#9FDCFF',
                    router: 'index',
                    sence: '基于超算的药物筛选和研发。提供软件和算力支持的方式加快药物筛选、基因组学检测分析等进程。让以往需以月乃至年为计的运算、检测时间被缩短到几天，大大缩短药物研制时间，快速进入临床治疗。服务平台可基于超算中心的算力支撑，对接药物研发企业的研发能力和药物生产企业的生产能力。',
                    tech: '基于超算的数字化服务；面向制造服务生态体系的数据交换方法'
                },
                {
                    itemCode: '02',
                    name: '机械装备行业',
                    bg: require('../../assets/img/index/jiXie.jpg'),
                    icon: 'icon-jixie',
                    color: '#ff6900',
                    color2: '#ffbd73',
                    router: 'index',
                    sence: '提供装备的智能运维服务。基于智能传感设备和智能边缘计算网关等实现对装备的实时监测与预警，提供从边缘计算到工业云平台端到端的服务，达到装备安全长周期运行维护的目的。使机械装备生产企业实现服务型制造转型。',
                    tech: '基于AI+5G+IoT的云边端架构设备远程运维与故障诊断；工业海量数据管理与处理技术；面向全生命周期的制造服务数据治理。'
                },
                {
                    itemCode: '03',
                    name: '汽车行业',
                    bg: require('../../assets/img/index/qiChe.jpg'),
                    icon: 'icon-qiche',
                    color: '#008638',
                    color2: '#9cffd2',
                    router: 'index',
                    sence: '商用车全生命周期服务平台。例如陕西汽车“车轮滚滚”O2O服务平台，以整车产品为基础，在持续提升基本售后服务的同时，开展融资租赁、经营性租赁、商业保险、保险经纪、车联网数据服务等一系列增值服务业务。',
                    tech: '产品服务系统设计与优化技术；制造服务智能交易技术；面向制造服务生态体系的数据交换方法。'
                },
                {
                    itemCode: '04',
                    name: '厨电行业',
                    bg: require('../../assets/img/index/chuFang.jpg'),
                    icon: 'icon-youyanji',
                    color: '#9200e5',
                    color2: '#d19fff',
                    router: 'index',
                    sence: '提供基于传感和人工智能技术的烹饪曲线模型。例如老板电器-中式烹饪曲线的建立。已开始，通过AI人工智能精准追踪时间和温度两大核心指标，掌控每道菜式的烹饪节奏，建设中式烹饪曲线数据库，形成烹饪曲线优化，模型进一步推动厨电的智能升级，提升用户体验。',
                    tech: '基于传感和智能技术的制造和服务深度融合；基于IoT和AI的设备远程管理和维护；制造服务数据的安全隐私保护。'
                },
                {
                    itemCode: '05',
                    name: '纺织行业',
                    bg: require('../../assets/img/index/fangZhi.jpg'),
                    icon: 'icon-duanxiujikouyifu',
                    color: '#c5a500',
                    color2: '#c9c579',
                    router: 'index',
                    sence: '提供纺织服装全产业敏捷供应链管理，实现产业链上下游敏捷协同。上游由供应商乃至供应商的供应商组成的企业合作伙伴关系，下游由生产商、分销商、零售商、终端消费者组成的用户关系。面向供应链的统一管理，促进信息流、资金流、物流的协同整合，提升供应链整体的效率和效益。例如阿里云IoT技术帮助企业实现实时控制和智能制造，大幅提高行业供应链能力，助力服装行业整体效率水平提升。5G MES技术应用于服装行业，推动工厂侧云、边、端的数据数据闭环，最终实现服装行业产业链透明互联。秒优等合作伙伴依托服装行业工业互联网平台，形成了可快速复制的智能制造创新模式，数据驱动整个服装生产流程，通过系统集成、精益管理、IoT技术、智能排产、智能在线调度、数字化绩效手段，将原本大批量生产模式转化为连续的、小批量、个性化、高效益的生产模式，真正做到快速精准去库存，实现小单快返的终极目标。',
                    tech: '网络驱动的制造产品服务化设计；制造服务智能交易技术。'
                },
                {
                    itemCode: '06',
                    name: '包装行业',
                    bg: require('../../assets/img/index/baoZhuang.jpg'),
                    icon: 'icon-wuliaobaozhuangdan',
                    color: '#409EFF',
                    color2: '#9FDCFF',
                    router: 'index',
                    sence: '提供基于AI+AR的包装定制化设计服务。提供具有专业包装设计能力的人工智能和增强现实服务，客户只需在包装商城上输入包装设计需求，就会自动快速提供若干种方案供其选择，并且能呈现最终的三维立体、增强现实效果。具有强大的学习能力，学习用户习惯、市场偏好等等，这些都将成为它的“知识储备”，更好地为用户提供包装设计服务。',
                    tech: '基于数字孪生和工业互联网的数字化服务技术；产品与服务系统设计优化方法。'
                },
                {
                    itemCode: '07',
                    name: '家具行业',
                    bg: require('../../assets/img/index/jiaJu.jpg'),
                    icon: 'icon-shafa',
                    color: '#ff6900',
                    color2: '#ffbd73',
                    router: 'index',
                    sence: '提供易简大规模个性化定制服务模式，实现“先设计，再销售，后生产”。破解定制家居生产的周期长、出错率高、品质差和规模生产难的难题，实现了规模个性化设计、大规模个性化定制与大规模个性化服务，有效把制造与服务融合，为消费者提供一站式的全新的定制家居消费体验。',
                    tech: '基于数字孪生和工业互联网的数字化服务技术；基于人工智能技术的制造和服务深度融合。'
                },
                {
                    itemCode: '08',
                    name: '生鲜食品行业',
                    bg: require('../../assets/img/index/shengXian.jpg'),
                    icon: 'icon-shuiguo',
                    color: '#008638',
                    color2: '#9cffd2',
                    router: 'index',
                    sence: '提供冷链物流全过程、全周期服务，实现冷链全过程监测与追溯，检验检疫服务。例如阿里的盒马鲜生。盒马运用大数据、移动互联、智能物联网、自动化等技术及先进设备，实现人、货、场三者之间的最优化匹配，平台拥有从供应链、仓储到配送的完整物流体系。',
                    tech: '网络驱动的产品服务化设计；服务数据交换方法、定价模型和智能交易技术。'
                },
                {
                    itemCode: '09',
                    name: '电梯行业',
                    bg: require('../../assets/img/index/dianTi.jpg'),
                    icon: 'icon-dianti-xiantiao',
                    color: '#9200e5',
                    color2: '#d19fff',
                    router: 'index',
                    sence: '提供电梯设备预测性维护、设备全生命周期管理以及提供可信的透明监管和全链路的安全保障。例如，通过工业互联网的规则引擎与阿里云其他云产品打通，实现采集+计算+存储+AI+可信的全栈服务，充分挖掘物联网数据的价值，实现连接到智能的跨越；通过构建数据平台，提供设备预测与分析统计服务，可以实现电梯设备预测性维护、设备全生命周期管理以及提供可信的透明监管和全链路的安全保障。基于可信电梯物联网平台实施的垂直电梯远程监测解决方案，采用不触碰电梯本身的安装方式，快速适配老旧电梯，采集上报各种数据，比如电梯运行状态信息，是否有人信息、所处楼层信息、实时故障等信息，接入到远程监控系统。结合后台数据为客户提供故障分析服务以及故障处理救援服务、智能维护服务等。',
                    tech: '基于传感和智能技术的制造和服务深度融合；基于IoT和AI的设备远程管理和维护；全生命周期的制造服务数据治理。'
                },
                {
                    itemCode: '10',
                    name: '精密模具行业',
                    bg: require('../../assets/img/index/muJu.jpg'),
                    icon: 'icon-yewumoban',
                    color: '#409EFF',
                    color2: '#9FDCFF',
                    router: 'index',
                    sence: '采用“平台+小微”方式，实现“共享制造”。例如深圳市五鑫科技的“Mould Lao众创空间”，以赋能的方式帮助平台内小微企业及各个技术创客实现从被动劳动变成自主竞价模式下的技能共享。小微企业的每笔订单，以内部市场竞标的方式由小微及创客自主报价，相互竞标。竞标成功后，平台通过共享制造系统对每个创客的工作进度和制造品质进行实时跟踪，他们完成一天的工作后可以立即扫码结算。平台将大量小微企业整合到一起，通过大数据、人工智能等技术，使供需之间能够实现智能化高效匹配。',
                    tech: '服务数据交换方法、定价模型和智能交易技术；网络驱动的协同制造技术。'
                }
            ],
            anys: [
                {
                    name: '注册企业数',
                    val: 36901,
                    icon: 'icon-company-fill',
                    color1: '#fff6e0',
                    color2: '#FFE7AA',
                    color3: '#725100'
                },
                {
                    name: '服务项总数',
                    val: 969004,
                    icon: 'icon-fuwu',
                    color1: '#baefff',
                    color2: '#52d6ff',
                    color3: '#004960'
                },
                {
                    name: '订单成交量',
                    val: 356707,
                    icon: 'icon-submenu11',
                    color1: '#e8d4ff',
                    color2: '#D19FFF',
                    color3: '#2f005b'
                },
                {
                    name: '注册用户数',
                    val: 576916,
                    icon: 'icon-yonghu',
                    color1: '#d6fff3',
                    color2: '#16ffca',
                    color3: '#004132'
                }
            ],
            curPlanIndex: 0,
            plans: [
                {
                    name: '业务数据上云',
                    po: 'center left',
                    detail: ['IT基础设施云化', '工厂设备上云', '应用云上托管', '部分业务SaaS化']
                },
                {name: '内部企业协同', po: 'center', detail: ['打通信息孤岛', '内部沟通协同', '业务交互协同']},
                {
                    name: '外部生产协同',
                    po: 'center right',
                    detail: ['外协与本厂生产流程数据化', '外协排产智能化，保证交期', '应用云上托管', '部分业务SaaS化']
                },
                {
                    name: '外部产销协同',
                    po: 'top 45%',
                    detail: ['生产与采购端协同，降低库存', '生产与消费端协同，按需生产']
                },
                {name: '产业整体协同', po: 'center 30%', detail: ['一码到底', '数据服务标准化', '标识解析应用']}
            ],
            components: [
                {name: '订单系统', color: '#409EFF', color2: '#2deeff', company: '山东新网科技有限公司'},
                {name: '派单系统', color: '#ff6900', color2: '#ffbd73', company: '山东新网科技有限公司'},
                {name: '生产系统', color: '#008638', color2: '#9cffd2', company: '山东新网科技有限公司'},
                {name: '测试系统', color: '#9200e5', color2: '#d19fff', company: '山东新网科技有限公司'},
                {name: '物流系统', color: '#ff004c', color2: '#ff4288', company: '山东新网科技有限公司'},
                {name: '代加工系统', color: '#409EFF', color2: '#9FDCFF', company: '山东新网科技有限公司'}
            ],
            sImg: require('../../assets/img/support1.png'),
            bannerList: [],
            fromLogin: false,
            config: {
                data: [
                    '12313131',
                    '3231kldksfks'
                ]
            },
            itemBodyStyle: {
                padding: '0',
                height: '120px'
            },
            newsBodyStyle: {
                padding: '0',
                height: '210px'
            },
            newsList: [],
            classOption: {
                step: 1,
                hoverStop: true,
                direction: 1,
                waitTime: 1000
            }
        }
    },
    computed: {
        name: {
            get() {
                return this.$store.state.user.name
            },
        },
        loginStatus: {
            get() {
                return this.$store.state.user.loginStatus
            },
            set(val) {
                this.$store.commit('user/updateLoginStatus', val)
            }
        },
        unRead: {
            get() {
                return this.$store.state.user.unRead
            }
        },
        enterpriseList: {
            get() {
                return this.$store.state.user.info
            },
            // set (val) { this.$store.commit('user/updateInfo', val) }
        },
        enterpriseId() {
            return this.enterpriseList[0] ? this.enterpriseList[0].id : ''
        }
    },
    watch: {
        enterpriseId(val) {
            this.initNews(val)
        }
    },
    created() {
        this.getCarousel()
        if (this.loginStatus) {
            this.initNews(this.enterpriseId)
        }
    },
    methods: {
        toService() {
            //
            let id = '80c8e59d54064359a0c7dc4b06903e35'
            this.$router.push({name: 'service', query: {id, isProvider: false}})
        },
        bannerChange(val) {
            this.curTradeIndex = val
        },
        bannerClick(index) {
            this.$router.push({name: 'plan-list', query: {trade: index, itemCode: `0${index + 1}`}})
        },
        search() {
            this.$router.push({name: 'plan-list', query: {search: this.planSearch, trade: this.curTradeIndex, itemCode: `0${this.curTradeIndex + 1}`}})
        },
        changeTrade(index) {
            this.curTradeIndex = index
            this.$refs.carousel.setActiveItem(index)
        },
        initNews(val) {
            // this.$http({
            //     url: this.$http.adornUrl(this.$api.QCC.NEWS.LIST),
            //     method: 'GET',
            //     params: this.$http.adornParams({
            //         enterpriseId: val
            //     })
            // }).then(({data}) => {
            //     if (data && data.code === 0) {
            //         this.newsList = [...data['result']['news']]
            //     }
            // })
        },
        /**
         * 查询轮播图
         */
        getCarousel() {
            this.$http({
                url: this.$http.adornUrl(this.$api.PUB.CAROUSEL),
                method: 'get',
                params: this.$http.adornParams()
            }).then(({data}) => {
                if (data && data.code === 0) {
                    this.bannerList = data.page.list
                }
            })
        },
        setActiveItem(index) {
            this.$refs.carousel.setActiveItem(index)
        },
        toImplement(index) {
            if (index === 4) {
                this.$router.push({name: 'implement'})
            } else if (index === 5) {
                this.$router.push({name: 'search', params: {itemCode: '20'}})
            }
        },
        goPage(page = '') {
            this.$router.push({name: page})
        }
    }
}
</script>
<style scoped lang="scss">
::v-deep .el-carousel__arrow {
  width: 60px;
  height: 60px;
  font-size: 20px;
  background-color: rgba(31, 45, 61, .31);
}

.plan-search {
  position: absolute;
  top: 70vh;
  z-index: 99999;
  width: 100%;

  div {
    box-shadow: 0 5px 40px 8px rgb(0 0 0 / 10%);

    ::v-deep .el-input__inner {
      height: 60px;
      font-size: 18px;
      //width: 80px;
    }

    .btn {
      width: 90px;
      font-size: 18px;
      margin-left: 10px;
    }
  }

}

.box1 {
  width: 100vw;
  height: 45vw;
  background: url("../../assets/img/index/plan-img1.jpg") no-repeat;
  background-size: cover;
  padding-top: 50px;

  .tit {
    font-size: 2vw;
    color: white;
    font-weight: bold;
    background: transparent;
  }

  .p-list {
    width: 12vw;
    height: 26vw;
    transition: all .3s ease;
    background: url("../../assets/img/index/plan-img3.jpg");
    color: white;
    font-family: 微软雅黑;
    padding: 2vw;

    .p-name {
      width: 3vw;
      font-size: 2vw;
      line-height: 2.4vw;
      text-align: center;
      font-weight: bold;
    }

    .p-num {
      padding: 1vw;
      font-size: 2.3vw;
      font-weight: bold;
    }

    .heng {
      width: 1.6vw;
      height: 2px;
      background: white;
    }

    .detail-box {
      padding-top: 5vw;

      .detail-list {
        font-size: 13px;
        line-height: 30px;
      }
    }
  }

  .on {
    width: 20vw;
    justify-content: left;
    background: rgba(0, 0, 0, .6);

    &:hover {
      background: rgba(0, 0, 0, .6);
    }
  }
}

::v-deep .el-carousel__arrow--right {
  right: 90px
}

::v-deep .el-carousel__arrow--left {
  left: 90px
}

::v-deep .el-carousel__indicators--horizontal {
  bottom: 100px;
}

.content-box {
  width: 1300px;
  margin: 0 auto;
}

.any-box {
  margin-top: 40px;
  margin-bottom: 50px;

  .a-list {
    position: relative;
    width: 22%;
    //height: 140px;
    overflow: hidden;
    padding: 40px 30px;
    border-radius: 10px;
    box-shadow: 0 0 20px rgba(0, 0, 0, .1);

    &:after {
      content: '';
      position: absolute;
      width: 120px;
      height: 120px;
      border-radius: 50%;
      background: #fff;
      opacity: .2;
      right: -25px;
      bottom: -25px;
    }

    .val {
      color: #333333;
    }

    .iconfont {
      position: absolute;
      font-size: 50px;
      right: 20px;
      bottom: 20px;
      color: white;
      text-shadow: 0 5px 10px rgba(0, 0, 0, .2);
    }
  }
}

.trades-navs {
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  z-index: 99;

  .tn-list {
    position: relative;
    width: 7vw;
    height: 3.94vw;
    color: white;
    border: 1px solid #fff;
    cursor: pointer;
    transition: all .3s ease;
    z-index: 0;

    &:hover .tn-dec {
      //transform: scale(1.2);
      //transform-origin: bottom;
      //z-index: 10;
      background: rgba(0, 0, 0, .6);
    }

    .tn-dec {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      z-index: 10;
      background: rgba(0, 0, 0, .3);
      text-align: center;

      .iconfont {
        font-size: 1.4vw;
      }

      .tn-name {
        font-size: .8vw;
        margin-top: 7px;
        font-weight: bold;
      }
    }
  }

  .on {
    transform: scale(1.2);
    transform-origin: bottom;
    z-index: 10;
  }
}

.title-name {
  font-size: 2vw;
  font-weight: bold;
  color: white;
  padding: 10px 30px;
  text-shadow: 0 0 20px rgba(0, 0, 0, .1);
}

.title-icon {
  width: 60px;
  height: 60px;
  position: relative;

  &:before {
    content: '';
    position: absolute;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    background: rgba(255, 255, 255, .2);
  }

  &:after {
    content: '';
    position: absolute;
    top: 30px;
    left: 30px;
    width: 30px;
    height: 30px;
    border-radius: 50%;
    background: rgba(255, 255, 255, .2);
  }
}

.ban-dec {
  position: absolute;
  top: 100px;
  left: 260px;
  right: 260px;
  bottom: 130px;

  .dec-box {
    text-align: center;
    //background: rgba(0, 0, 0, .1);
    //-webkit-backdrop-filter: blur(6px);
    //backdrop-filter: blur(6px);
    color: #ffffff;
    padding: 30px;

    .dec-tit {
      display: inline-block;
      background: transparent;
      //-webkit-backdrop-filter: blur(6px);
      //backdrop-filter: blur(6px);
      font-size: 5vw;
      letter-spacing: 5px;
      margin-bottom: 1vw;
      text-shadow: 0 0 30px rgba(0, 0, 0, .4);
    }

    .dec-dec {
      line-height: 35px;
      opacity: .7;
    }
  }
}

.header-container {
  /*margin-top: 10px;*/
  /*padding-top: 10px;*/
  display: flex;
  justify-content: center;
  /*padding-bottom: 40px;*/
  /*background-color: #FFFFFF;*/
  /*width: 1400px;*/
  /*height: 600px;*/
}

.warp {
  height: 180px;
  width: 100%;
  overflow: hidden;
}

.header-wrap {
  display: flex;
  justify-content: center;
}


.banner-box {
  width: 100%;
  min-width: 1300px;
  /*background-image: url("../../assets/img/self/self_background.png");*/
}

.main .banner .bg-img {
  width: 100vw;
  min-width: 1300px;
  height: 685px;
  background-size: 1920px 685px;
  background-position: center top;
}

.banner-box .preview {
  position: absolute;
  bottom: -40px;
  z-index: 11;
}

.banner-box .preview .wrap {
  width: 1000px;
  margin: 0 auto;
  background: #ffffff;
  padding: 20px 20px;
  border-radius: 8px;
  box-shadow: 0 0 28px rgba(0, 0, 0, 0.08);
}

.banner-box .mask {
  width: 100%;
  min-width: 1300px;
  height: 72px;
  display: block;
  position: absolute;
  bottom: 0;
  z-index: 10;
}

.banner-box .mask span {
  width: 100%;
  min-width: 1200px;
  height: 72px;
  display: block;
  background: url("../../assets/img/mask-grey.png") no-repeat;
  background-size: 100% 72px;
  background-position: center top;
}


.banner-right {
  /*background-color: red;*/
  margin: .2rem;
  width: 300px;
  height: 602px;
  border-radius: 0px 8px 8px 0px;
  /*background-color: #4647BB;*/
  background-color: #FFFFFF;
  display: flex;
  flex-direction: column;
  /*justify-content: flex-end;*/
  /*background-color: #f0f0f0;*/
}

.ent-name {
  color: #409EFF;
}

.card-hover {
  /*padding: .1rem;*/
  height: 20%;
  cursor: pointer;
}


@media screen and (max-width: 1400px) {
}
</style>
